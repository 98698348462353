'use client';

import { FC } from 'react';

import Divider from '@mui/material/Divider';
// eslint-disable-next-line import/no-cycle
import { ExpandMore } from '@mui/icons-material';
import {
  Box,
  Grid,
  Stack,
  Button,
  Accordion,
  TextField,
  Typography,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';

import Image from '../../../../components/image';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { useResponsive } from '../../../../hooks/use-responsive';

const ContactFormBig: FC<ComponentTypeProps> = ({ block }) => {
  const isMobile = useResponsive('down', 'sm');

  return (
    <Box sx={{ zIndex: 1000, position: 'relative' }}>
      {!isMobile && (
        <Stack px={5} mb={6} py={2} sx={{ background: 'white', borderRadius: '10px', justifyContent: 'center' }}>
          <Typography sx={{ mx: '50px' }}>השאירו פרטים ונחזור אליכם</Typography>
          <Stack direction="row" gap={1}>
            <TextField placeholder="שם הארגון" />
            <TextField placeholder="שם הנציג" />
            <TextField placeholder="מייל" />
            <TextField placeholder="טלפון נייד" />
            <Button
              variant="contained"
              sx={{
                borderRadius: '5px',
                px: 4,
                background:
                  'linear-gradient(92.59deg, #0177EC 60.08%, #0138C0 99.92%)',
              }}
            >
              שליחה
            </Button>
          </Stack>
        </Stack>
      )}
      <Grid container spacing={1} sx={{ justifyContent: 'space-between' }}>
        {isMobile && (
          <Grid item xs={12} width={1} sx={{background: 'white', borderRadius: '10px', p: 1, mb: 2}}>
            <Stack gap={1} width={1}>
              <TextField fullWidth placeholder="שם הארגון" />
              <TextField fullWidth placeholder="שם הנציג" />
              <TextField fullWidth placeholder="מייל" />
              <TextField fullWidth placeholder="טלפון נייד" />
              <Button
                variant="contained"
                sx={{
                  borderRadius: '5px',
                  px: 4,
                  background:
                    'linear-gradient(92.59deg, #0177EC 60.08%, #0138C0 99.92%)',
                }}
              >
                שליחה
              </Button>
            </Stack>
          </Grid>
        )}
        <Grid item xs={isMobile ? 12 : 8}>
          <Typography sx={{ fontSize: '25px', fontWeight: 1000 }}>
            עם B-smart כל האפשרויות פתוחות, רק תבחרו!
          </Typography>
          <Typography>כבר כולם יודעים...</Typography>
          <Typography>עסקים ב- B-smart מרוויחים הרבה יותר...</Typography>
          <Typography sx={{ mt: 2 }}>
            B-smart מציעה מועדון הטבות חכם לכולם, עם אפשרות רכישה מהירה ללא כל
            תלות בגורם מסוים, פשוט לקבל הטבות בדרך חכמה ופשוטה. לקוחות מגיעים לבית
            העסק שלכם ומעבירים את כרטיס B-smart הנמצא בארנק הדיגיטלי שלהם.. ללא
            כ״א פיזי, ללא שוברים, ללא הטענות.
          </Typography>
          <Typography sx={{ fontSize: '25px', fontWeight: 1000, mt: 2 }}>
            הצטרפות כנותני שירות
          </Typography>
          <Typography sx={{ fontSize: '20px', fontWeight: 1000, mt: 1 }}>
            איך זה עובד?
          </Typography>
          <Typography>
            לקוחות מורידים את כרטיס B-smart לארנק הדיגיטלי בסמארטפון, מבצעים רכישה
            מהירה בבית העסק שלכם ומקבלים את ההטבות ב-4 מסלולים לבחירה.
          </Typography>
          <Typography sx={{ fontSize: '25px', fontWeight: 1000, mt: 2 }}>
            אופן הפעילות בבית העסק
          </Typography>
          <Grid container spacing={2} mt={1}>
            {[
              'משאירים פרטים בטופס בעמוד זה',
              'מצטרפים ל-B-smart',
              'מחייבים את הלקוח בכרטיס הנמצא בארנק הדיגיטלי',
              'בית העסק מקבל זיכוי במועד הנקבע (יומי, שבועי, חודשי)',
            ].map((text, index) => (
              <Grid item xs={isMobile ? 12 : 6} key={index}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Box
                    sx={{
                      width: 40, // Диаметр круга
                      height: 40, // Диаметр круга
                      background:
                        'linear-gradient(92.59deg, #0177EC 60.08%, #0138C0 99.92%)',
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography
                      sx={{ fontSize: '20px', color: 'white', fontWeight: 1000 }}
                    >
                      {index + 1}
                    </Typography>
                  </Box>
                  <Typography sx={{ fontSize: '13px' }}>{text}</Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
          <Typography my={2}>
            *החיוב מופחת הטבה המסוכמת מראש מול בית העסק.
          </Typography>
          <Typography sx={{ fontSize: '25px', fontWeight: 1000, mt: 4 }}>
            היתרונות שלכם כעסק
          </Typography>
          <Grid container spacing={2} mt={1}>
            {[
              'בתי העסק נהנים מהנחה וחיסכון בגין עמלת סליקה',
              'האישור מתבצע ע”י B-smart, ולכן אין תשלום עמלת סליקה',
              'אפשרות להנחה נוספת בגין חיסכון זה לבית העסק',
              'נהנים מתזרים מזומנים נוסף, סכום הכנסה קבוע',
            ].map((text, index) => (
              <Grid item xs={isMobile ? 12 : 6} key={index}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Box
                    sx={{
                      width: 40, // Диаметр круга
                      height: 40, // Диаметр круга
                      background:
                        'linear-gradient(92.59deg, #0177EC 60.08%, #0138C0 99.92%)',
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Image
                      src="/assets/images/b-smart/done.png"
                      alt="done"
                      style={{ width: '100%', height: 'auto' }} // Размер изображения внутри круга
                    />
                  </Box>
                  <Typography sx={{ fontSize: '13px' }}>{text}</Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
          <Typography sx={{ fontSize: '25px', fontWeight: 1000, mt: 5, mb: 3 }}>
            כל האפשרויות כאן...
          </Typography>
          <Typography sx={{ fontSize: '20px', fontWeight: 1000, my: 3 }}>
            אודות הפרוייקט
          </Typography>
          <Typography>
            אפליקציה זו נועדה בכדי להקל עליכם, בעלי הדירות, לנהל תקציבים וכספים
            למען שדרוג הדירה ולשלם לספקים הנבחרים. באפליקציה זו, כל כך קל וברור
            לבצע פעולות אלו, בזמן שנראות האפליקציה נוחה יותר ומתמיד. ניהול תקציבים
            ותשלם לספקים לא צריך להיות דבר מסובך, ואפליקציה זו בדיוק באה לשרת זאת.
            תוכלו לנהל את תקציבכם היישר מן הנייד ללא בסיוע אדם נוסף, בצורה הכי
            ברורה וקלה שיש.
          </Typography>
          <Divider sx={{ width: '100%', mt: 2 }} />
          <Stack
            spacing={2}
            my={2}
            sx={{ direction: block?.settings?.forceRtl ? 'rtl' : 'ltr' }}
          >
            {[
              'האם הספקים באפליקציה זו בטוחים ואמינים?',
              'כיצד ניתן לשלם? מהם אמצעי התשלום?',
              'האם הספקים באפליקציה זו בטוחים ואמינים?',
              'כיצד ניתן לשלם? מהם אמצעי התשלום?',
            ].map((item: string, idx: number) => (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography sx={{}}>{item}</Typography>
                </AccordionSummary>
                <AccordionDetails />
              </Accordion>
            ))}
          </Stack>
          <Divider sx={{ width: '100%', mb: 2 }} />
          <Image src="/assets/images/b-smart/banner.png" />
        </Grid>
        {!isMobile && (
        <Grid item xs={3}>
          <Stack gap={1}>
            <TextField placeholder="שם הארגון" />
            <TextField placeholder="שם הנציג" />
            <TextField placeholder="מייל" />
            <TextField placeholder="טלפון נייד" />
            <Button
              variant="contained"
              sx={{
                borderRadius: '5px',
                px: 4,
                background:
                  'linear-gradient(92.59deg, #0177EC 60.08%, #0138C0 99.92%)',
              }}
            >
              שליחה
            </Button>
          </Stack>
        </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ContactFormBig;

'use client';

import React, { FC } from 'react';

import { Stack, Divider, Container, Typography } from '@mui/material';

import { ComponentTypeProps } from '../../../../types/page-generator';

const TitleAndDivider: FC<ComponentTypeProps> = ({ block }) => {
  const text: any = block?.titleText;
  return (
    <Container maxWidth="xl">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ direction: 'rtl' }}
      >
        <Typography
          sx={{
            direction: block?.settings.forceRtl ? 'rtl' : 'ltr',
            color: block?.settings.contentTitleTextColor,
            fontSize: block?.settings.contentTitleFontSize,
            fontStyle: block?.settings.contentTitleFontStyle,
            fontWeight: block?.settings.contentTitleFontWeight,
            textDecoration: block?.settings.contentTitleTextDecoration,
            textAlign: block?.settings.contentTitleTextAlign,
            width: '100%',
          }}
        >
          {text}
        </Typography>
      </Stack>
      <Divider sx={{ width: '100%', mt: 1 }} />
    </Container>
  );
};

export default TitleAndDivider;

'use client';

import { FC } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {
  Stack,
  Divider,
  Typography,
} from '@mui/material';

import { useLocales } from '../../../../locales';
import Image from '../../../../components/image';
import Iconify from '../../../../components/iconify';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { uploadProductsImage } from '../../../../helpers/uploadProductsImage';

const NewFourItems: FC<ComponentTypeProps> = ({ block }) => {
  const { combinedBottomBanner }: any = block || [];
  const { currentLang } = useLocales();
  const isMobile = useResponsive('down', 'sm');
  return (
    <>{!isMobile ? (
      <Box width={1} sx={{ position: 'relative' }}>
        <Stack width={1} alignItems="center">
          <Stack
            sx={{
              width: '100%',
              background: 'white',
              borderRadius: '10px',
            }}
            direction="row"
            justifyContent="space-evenly" // Равномерное распределение элементов
            alignItems="center" // Центрирование по вертикали
            gap={2} // Увеличение промежутков между элементами
          >
            {Array.isArray(combinedBottomBanner) &&
              combinedBottomBanner.map((i: any, id: number) => (
                <Stack direction="row" key={id} gap={2} alignItems="center">
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      height: 60, // Чёткие числа без кавычек
                      width: 120,
                      borderRadius: '50%',
                      display: 'flex',
                      overflow: 'hidden', // Защита от искажений
                      background: block?.settings?.addGradientStyle
                        ? `linear-gradient(90deg, ${block?.settings?.firstGradientColor} ${block?.settings?.firstGradientColorStop}%, ${block?.settings?.secondGradientColor} ${block?.settings?.secondGradientColorStop}%, ${block?.settings?.thirdGradientColor} ${block?.settings?.thirdGradientColorStop}% )`
                        : 'blue',
                    }}
                  >
                    {block?.settings?.viewVariant === 'icon' ? (
                      <Iconify
                        icon={i.bottomIcon}
                        sx={{ color: 'white', width: 30, height: 30 }}
                      />
                    ) : (
                      <Image
                        src={uploadProductsImage(i?.bottomImage)}
                        sx={{
                          '& img': { width: 40, height: 40 }, // Пропорциональная настройка изображения
                        }}
                      />
                    )}
                  </Stack>


                  <Typography
                    className={i.typographyClass}
                    sx={{
                      textAlign: 'center', // Центрирование текста
                      width: '100%',
                      direction: currentLang.value === 'he' ? 'ltr' : 'rtl',
                      lineHeight: i.lineHeight || 1,
                    }}
                  >
                    <span dangerouslySetInnerHTML={{ __html: i?.bottomText }} />
                  </Typography>
                  {id !== combinedBottomBanner.length - 1 && (
                    <Divider
                      orientation="vertical"
                      sx={{ height: '50px', mx: 2 }}
                    />
                  )}
                </Stack>
              ))}
          </Stack>
        </Stack>
      </Box>
    ) : (
      <Grid container spacing={1}>
        {combinedBottomBanner.map((item: any, id: number) => (
          <Grid item xs={3} key={id}>
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                height: 60, // Чёткие числа без кавычек
                width: 60,
                borderRadius: '50%',
                display: 'flex',
                overflow: 'hidden', // Защита от искажений
                background: block?.settings?.addGradientStyle
                  ? `linear-gradient(90deg, ${block?.settings?.firstGradientColor} ${block?.settings?.firstGradientColorStop}%, ${block?.settings?.secondGradientColor} ${block?.settings?.secondGradientColorStop}%, ${block?.settings?.thirdGradientColor} ${block?.settings?.thirdGradientColorStop}% )`
                  : 'blue',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {block?.settings?.viewVariant === 'icon' ? (
                <Iconify
                  icon={item.bottomIcon}
                  sx={{ color: 'white', width: 30, height: 30 }}
                />
              ) : (
                <Image
                  src={uploadProductsImage(item?.bottomImage)}
                  sx={{
                    '& img': { width: 40, height: 40 }, // Пропорциональная настройка изображения
                  }}
                />
              )}
            </Stack>

            <Typography
              className={item.typographyClass}
              sx={{
                textAlign: 'center', // Центрирование текста
                width: '100%',
                direction: currentLang.value === 'he' ? 'ltr' : 'rtl',
                lineHeight: 0.5,
                fontSize: '11px'
              }}
            >
              <span dangerouslySetInnerHTML={{ __html: item?.bottomText }} />
            </Typography>
          </Grid>
        ))}
      </Grid>
    )}
    </>
  );
};

export default NewFourItems;

'use client';

import { FC } from 'react';

import { Box, Grid, Stack, Button, TextField, Typography } from '@mui/material';

import Image from '../../../../components/image';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { useResponsive } from '../../../../hooks/use-responsive';

const ContactForm: FC<ComponentTypeProps> = ({ block }) => {
  const isMobile = useResponsive('down', 'sm');

  return (
    <Box sx={{ zIndex: 1000, position: 'relative' }}>
      {!isMobile && (
        <Stack px={5} mb={6} py={2} sx={{ background: 'white', borderRadius: '10px' }}>
          <Typography sx={{ mx: '50px' }}>השאירו פרטים ונחזור אליכם</Typography>
          <Stack direction="row" gap={1} justifyContent="center">
            <TextField placeholder="שם הארגון" />
            <TextField placeholder="שם הנציג" />
            <TextField placeholder="מייל" />
            <TextField placeholder="טלפון נייד" />
            <Button
              variant="contained"
              sx={{
                borderRadius: '5px',
                px: 4,
                background:
                  'linear-gradient(92.59deg, #0177EC 60.08%, #0138C0 99.92%)',
              }}
            >
              שליחה
            </Button>
          </Stack>
        </Stack>
      )}
      <Grid container spacing={1} sx={{ justifyContent: 'space-between' }}>
        {isMobile && (
          <Grid item xs={12} width={1} sx={{ background: 'white', borderRadius: '10px', p: 1, mb: 2 }}>
            <Stack gap={1} width={1}>
              <TextField fullWidth placeholder="שם הארגון" />
              <TextField fullWidth placeholder="שם הנציג" />
              <TextField fullWidth placeholder="מייל" />
              <TextField fullWidth placeholder="טלפון נייד" />
              <Button
                variant="contained"
                sx={{
                  borderRadius: '5px',
                  px: 4,
                  background:
                    'linear-gradient(92.59deg, #0177EC 60.08%, #0138C0 99.92%)',
                }}
              >
                שליחה
              </Button>
            </Stack>
          </Grid>
        )}
        <Grid item xs={isMobile ? 12 : 8}>
          <Typography sx={{ fontSize: '25px', fontWeight: 1000 }}>
            הקמת מועדון הטבות לארגונים וחברות
          </Typography>
          <Typography>תמיד רציתם להקים בארגון מועדון הטבות, אבל שונה?</Typography>
          <Typography>לא כמו של כולם...</Typography>
          <Typography>
            B-smart מביאה לארגונים פתרון מושלם ומקימה מועדון הטבות בהתאמה אישית.
            המועדון מספק לארגון את הכלים והיכולות ליצור לעובדים חוויה ייחודית,
            כזאת שמגדילה את שביעות הרצון שלהם ומשמרת אותם בטווח הארוך.
          </Typography>
          <Stack
            gap={2}
            alignItems="center"
            sx={{
              width: '100%',
            }}
            mt={2}
          >
            {/* Первый компонент */}
            <Stack
              sx={{
                border: '2px solid rgba(1, 119, 236, 1)',
                borderRadius: '5px',
                maxWidth: '100%', // Адаптивность
              }}
              direction={isMobile ? 'column' : 'row'}
            >
              <Stack
                width={isMobile ? '100%' : '30%'} // Пропорциональная ширина синего фона
                justifyContent="center"
                sx={{
                  background:
                    'linear-gradient(92.59deg, #0177EC 40.08%, #0138C0 99.92%)',
                }}
              >
                <Typography
                  mx={2}
                  sx={{ color: 'white', fontSize: '20px', fontWeight: 1000, textAlign: isMobile ? 'center' : '' }}
                >
                  רגע,
                </Typography>
                <Typography
                  mx={2}
                  sx={{ color: 'white', fontSize: '20px', fontWeight: 1000, textAlign: isMobile ? 'center' : '' }}
                >
                  {' '}
                  מה זה בכלל?
                </Typography>
              </Stack>
              <Typography sx={{ p: '10px', width: isMobile ? '100%' : '60%' }}>
                המועדון מבוסס על טכנולוגיה מאובטחת וחדשנית הפועלת באמצעות כרטיס
                דיגיטלי חכם הממוקם בארנק הדיגיטלי בסמארטפון - ללא שוברים וללא
                כרטיס פיזי וללא שום תלות בגורם אחר. באמצעות הכרטיס הדיגיטלי,
                העובדים יוכלו ליהנות מהטבות ייחודיות, הנחות וסבסוד ברשתות שונות,
                בהתאמה לצרכים ולהעדפות של הארגון. אנחנו כאן כדי לדאוג שהארגון
                יעניק לעובדים את הדה-בסט!
              </Typography>
            </Stack>

            {/* Второй компонент */}
            <Stack
              sx={{
                border: '2px solid rgba(1, 119, 236, 1)',
                borderRadius: '5px',
                maxWidth: '100%', // Адаптивность
              }}
              direction={isMobile ? 'column' : 'row'}
            >
              <Stack
                width={isMobile ? '100%' : '30%'} // Пропорциональная ширина синего фона
                justifyContent="center"
                sx={{
                  background:
                    'linear-gradient(92.59deg, #0177EC 40.08%, #0138C0 99.92%)',
                }}
              >
                <Typography
                  mx={2}
                  sx={{ color: 'white', fontSize: '20px', fontWeight: 1000, textAlign: isMobile ? 'center' : '' }}
                >
                  התאמה אישית וממוקדת
                </Typography>
                <Typography
                  mx={2}
                  sx={{ color: 'white', fontSize: '20px', fontWeight: 1000, textAlign: isMobile ? 'center' : '' }}
                >
                  {' '}
                  לצרכי הארגון
                </Typography>
              </Stack>
              <Typography sx={{ p: '10px', width: isMobile ? '100%' : '60%' }}>
                כל ארגון הוא ייחודי, ולכן B-smart מאפשרת לכל ארגון לבנות מועדון
                הטבות שמתאים באופן מושלם לפרופיל העובדים. לדוגמה, אם הארגון שם דגש
                על עבודה מרחוק, ניתן להציע לעובדים הנחות או סבסוד מלא על מחשוב
                וציוד ברשת מסוימת. לעובדים עם ילדים, ניתן להציע הטבות ייחודיות
                להם, לגמלאים הטבות במועדוני כושר וכך הלאה.
              </Typography>
            </Stack>
          </Stack>
          <Grid container spacing={2} mt={4}>
            {[
              'רכישת תווים בקליק לחגים',
              'מתנות לימי הולדת לעובדים',
              'הנחות מוגדלות לעובדים ספציפיים',
              'הנחות ברשתות מסוימות',
              'אפשרות רכישת מארז עם מוצרים מסוימים',
              'ועוד...',
            ].map((text, index) => (
              <Grid item xs={isMobile ? 12 : 6} key={index}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Box
                    sx={{
                      width: 40, // Диаметр круга
                      height: 40, // Диаметр круга
                      background:
                        'linear-gradient(92.59deg, #0177EC 60.08%, #0138C0 99.92%)',
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Image
                      src="/assets/images/b-smart/done.png"
                      alt="done"
                      style={{
                        width: '100%', height: 'auto',
                      }} // Размер изображения внутри круга
                      sx={{
                        '& img': {
                          width: 30,
                          height: 30,
                          mx: 0.5,
                        },
                      }}
                    />
                  </Box>
                  <Typography sx={{ fontSize: '13px' }}>{text}</Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Grid>
        {!isMobile && (
          <Grid item xs={3}>
            <Stack gap={1}>
              <TextField placeholder="שם הארגון" />
              <TextField placeholder="שם הנציג" />
              <TextField placeholder="מייל" />
              <TextField placeholder="טלפון נייד" />
              <Button
                variant="contained"
                sx={{
                  borderRadius: '5px',
                  px: 4,
                  background:
                    'linear-gradient(92.59deg, #0177EC 60.08%, #0138C0 99.92%)',
                }}
              >
                שליחה
              </Button>
            </Stack>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ContactForm;

'use client';

import { FC, useMemo } from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { getContentValueFromProps } from '../../utils';
import { useLocales } from '../../../../locales';
import { useResponsive } from '../../../../hooks/use-responsive';

type ButtonItem = {
  img: string;
  button: any;
};

const CatalogV2: FC<ComponentTypeProps> = ({ block }) => {
  const getFieldValue = getContentValueFromProps(block);
  const { t } = useTranslation();
  const combinedBlock: any = block?.combinedBlock;
  const { currentLang } = useLocales();
  const isMobile = useResponsive('down', 'sm');

  const indentation = useMemo(
    () => getFieldValue('settings.indentation'),
    [block?.settings.indentation],
  );

  const gridMd =
    combinedBlock && combinedBlock.length ? 12 / combinedBlock.length : 3;

  return (
    <Grid container sx={{ height: 'auto' }} spacing={1}>
      {combinedBlock &&
        combinedBlock?.map((item: any, id: number) => (
          <Grid item md={gridMd} xs={isMobile ? 12 : 6} key={id}>
            <Stack
              key={`${item.img}_${id}`}
              alignItems="center"
              sx={{
                position: 'relative',
                backgroundImage: `url(${item.image})`,
                backgroundPosition: '50%',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                width: '100%',
                height: '100%',
                border: '2px solid rgba(1, 119, 236, 1)',
                borderRadius: '10px',
              }}
            >
              <Typography
                sx={{
                  '& h1': { my: 1 },
                  '& h2': { my: 1 },
                  '& h3': { my: 1 },
                  '& h4': { my: 1 },
                  '& h5': { my: 1 },
                  '& h6': { my: 1 },
                  '& p': { margin: 0 },
                  direction: currentLang.value === 'he' ? 'ltr' : 'rtl',
                  width: '100%',
                  color: 'white',
                  background:
                    'linear-gradient(92.59deg, #0177EC 60.08%, #0138C0 99.92%)',
                  py: 3,
                  fontWeight: 1000
                }}
              >
                <span dangerouslySetInnerHTML={{ __html: item.title }} />
                {/* <SanitizeHTML html={contentText} /> */}
              </Typography>
              <Typography
                sx={{
                  mt: 2,
                  direction: currentLang.value === 'he' ? 'ltr' : 'rtl',
                  width: '85%',
                  textAlign: 'center',
                  fontSize: '14px',
                  py: 3,
                }}
              >
                {item.subtitle}
              </Typography>
            </Stack>
          </Grid>
        ))}
    </Grid>
  );
};

export default CatalogV2;

'use client';

import { FC } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import Image from '../../../../components/image';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { useResponsive } from '../../../../hooks/use-responsive';

const TextAndImage: FC<ComponentTypeProps> = ({ block }) => {
  const isMobile = useResponsive('down', 'sm');

  return (
    <Stack direction={isMobile ? 'column' : 'row'} mx={isMobile ? 0 : 10} gap={2} justifyContent="space-around">
      <Box mt={3} width={1}>
        <Typography sx={{ fontWeight: 1000, fontSize: '20px', my: 2 }}>
          מה זה B Smart לילדים? טוב ששאלתם...
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '14px' }}>
          עם B Smart לילדים אתם שולטים על ההוצאות של הילדים!{' '}
        </Typography>
        <Typography sx={{ fontWeight: 1000, fontSize: '14px' }}>
          מכירים את זה שהילד מבקש כסף מזומן ואין עליכם?
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '14px' }}>
          B-smart מאפשרת לילדים בגילאי 14- 18 להוריד כרטיס לארנק הדיגיטלי
          בסמארטפון.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '14px' }}>
          הכרטיס מבוסס על תשלום נגד יתרה בלבד וניתן להשתמש בו בכל בית עסק. ניתן
          להעביר
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '14px' }}>
          את ההטבות שנצברו במסלולים או להטעין לילד תשלום בקליק.
        </Typography>
      </Box>
      <Box>
        <Image sx={{ height: '250px' }} src="/assets/images/b-smart/Iphone.png" />
      </Box>
    </Stack>
  );
};

export default TextAndImage;

'use client';

import { FC } from 'react';
import Slider from 'react-slick';
import Stack from '@mui/material/Stack';
import { Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useSelector } from '../../../../redux/store';
import Iconify from '../../../../components/iconify';
import Image from '../../../../components/image';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { useResponsive } from '../../../../hooks/use-responsive';
import { useRouter } from '../../../../routes/hooks';

export const SalesCarousel: FC<ComponentTypeProps> = ({ block }) => {
  const actionButtons: any = block?.actionButtons || [];
  let sliderRef: Slider | any;
  const text: string = block?.text as string;
  const size = block?.settings?.enableDouble ? 5 : 8;
  const isMobile = useResponsive('down', 'sm');
  const router = useRouter();

  const array = [
    { image: '/assets/images/b-smart/sales/Sale 1.png' },
    { image: '/assets/images/b-smart/sales/Sale 2.png' },
    { image: '/assets/images/b-smart/sales/Sale 3.png' },
    { image: '/assets/images/b-smart/sales/Sale 1.png' },
    { image: '/assets/images/b-smart/sales/Sale 2.png' },
    { image: '/assets/images/b-smart/sales/Sale 4.png' },
  ];

  const CustomPrevArrow = ({ onClick }: any) => (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: 'transparent',
        position: 'absolute',
        top: '50%',
        right: '-40px',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 100,
      }}
    >
      <Iconify icon="ep:arrow-left-bold" color="rgba(14, 23, 50, 1)" />
    </Box>
  );

  const CustomNextArrow = ({ onClick }: any) => (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: 'transparent',
        position: 'absolute',
        top: '50%',
        left: '-40px',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 100,
      }}
    >
      <Iconify icon="ep:arrow-right-bold" color="rgba(14, 23, 50, 1)" />
    </Box>
  );

  const handlePrevClick = () => {
    sliderRef.slickPrev();
  };

  const handleNextClick = () => {
    sliderRef.slickNext();
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: !isMobile,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    nextArrow: <CustomPrevArrow onClick={handlePrevClick} />,
    prevArrow: <CustomNextArrow onClick={handleNextClick} />,
  };

  return (
    <Box
      sx={{
        '& .slick-track': { mt: '10px' },
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 2 }}
      >
        <Typography sx={{ fontSize: '22px', fontWeight: 800 }}>
          {text}
        </Typography>
        {actionButtons.length > 0 && (
          <Stack direction="row" alignItems="center">
            <Typography sx={{ mr: 1, cursor: 'pointer' }}
                        onClick={() => router.push(`${actionButtons[0].link}`)}>{actionButtons[0]?.label}</Typography>
          </Stack>
        )}
      </Stack>
      <Divider sx={{ my: 2 }} />

      <Slider {...settings}>
        {array?.map((item: { image: string }, id: number) => (
          <Image src={item.image} />
        ))}
      </Slider>
    </Box>
  );
};

'use client';

import { FC } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import Image from '../../../../components/image';
import { uploadProductsImage } from '../../../../helpers/uploadProductsImage';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { useLocales } from '../../../../locales';
import { useResponsive } from '../../../../hooks/use-responsive';

const InfoBlockWithImage: FC<ComponentTypeProps> = ({ block }) => {
  const image: string = block?.image as string;
  const mainTitle: string = block?.mainTitle as string;
  const subTitle: string = block?.subTitle as string;
  const titleSmartBlock: string = block?.titleSmartBlock as string;
  const text: string = block?.text as string;
  const qrCode: string = block?.qrCode as string;
  const combined: any = block?.combined || [];
  const enableQrCode: any = block?.enableQrCode;
  const { currentLang } = useLocales();
  const isMobile = useResponsive('down', 'sm');

  return (
    <Grid container spacing={1}>
      <Grid item xs={isMobile ? 12 : 8}>
        <Stack gap={1} mt={4}>
          <Stack>
            <Typography
              sx={{
                '& h1': { my: 1 },
                '& h2': { my: 1 },
                '& h3': { my: 1 },
                '& h4': { my: 1 },
                '& h5': { my: 1 },
                '& h6': { my: 1 },
                '& p': { margin: 0 },
                direction: currentLang.value === 'he' ? 'ltr' : 'rtl',
                width: '100%',
                lineHeight: 1,
              }}
            >
              <span dangerouslySetInnerHTML={{ __html: mainTitle }} />
            </Typography>
            <Typography
              sx={{
                '& h1': { my: 1 },
                '& h2': { my: 1 },
                '& h3': { my: 1 },
                '& h4': { my: 1 },
                '& h5': { my: 1 },
                '& h6': { my: 1 },
                '& p': { margin: 0 },
                direction: currentLang.value === 'he' ? 'ltr' : 'rtl',
                width: '100%',
                lineHeight: 1,
              }}
            >
              <span dangerouslySetInnerHTML={{ __html: subTitle }} />
            </Typography>
          </Stack>
          <Stack gap={1} mt={2}>
            <Typography sx={{ fontWeight: 1000, fontSize: '16px', mt: 3, mb: 1 }}>
              {titleSmartBlock}
            </Typography>
            <Grid container spacing={2}>
              {combined.map((item: any, id: number) => (
                <Grid item xs={isMobile ? 12 : 6} key={id}>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    sx={{ position: 'relative' }}
                  >
                    {/* Круг с номером */}
                    <Box
                      sx={{
                        width: '50px',
                        height: '50px',
                        background:
                          'linear-gradient(92.59deg, #0177EC 60.08%, #0138C0 99.92%)',
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          color: 'white',
                          fontWeight: 'bold',
                          fontSize: '20px',
                        }}
                      >
                        {id + 1}
                      </Typography>
                    </Box>

                    {/* Текст */}
                    <Typography
                      sx={{
                        fontSize: '12px',
                        '& h1': { my: 1 },
                        '& h2': { my: 1 },
                        '& h3': { my: 1 },
                        '& h4': { my: 1 },
                        '& h5': { my: 1 },
                        '& h6': { my: 1 },
                        '& p': { margin: 0 },
                        direction: currentLang.value === 'he' ? 'ltr' : 'rtl',
                        width: '50%',
                        lineHeight: block?.settings?.lineHeight,
                      }}
                    >
                      <span dangerouslySetInnerHTML={{ __html: item?.text }} />
                    </Typography>
                    {!isMobile && (<>
                      {item?.enableQrCode && (
                        <Image
                          src={uploadProductsImage(item?.qrCode)}
                          sx={{
                            width: '50px',
                            height: '50px',
                            objectFit: 'contain',
                            position: 'absolute',
                            right: '130px',
                          }}
                        />
                      )}
                    </>)}
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Stack>
        </Stack>
      </Grid>
      {!isMobile && (
        <Grid item xs={4}>
          <Image src={uploadProductsImage(image)} />
        </Grid>
      )}
    </Grid>
  );
};

export default InfoBlockWithImage;

'use client';

import { FC, useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Image from '../../components/image';
import { Ib2bProduct } from '../../types/b2b';
import { BlockType } from '../../types/generator';
import { PriceTypeEnum } from '../../types/product';
import { uploadProductsImage } from '../../helpers/uploadProductsImage';
import { useRouter } from '../../routes/hooks';

type Props = {
  block: BlockType | any;
  product: Ib2bProduct | any;
};

export const AlternativeProductCarousel: FC<Props> = ({ block, product }) => {
  const [matchingTags, setMatchingTags] = useState<any[]>([]);
  const router = useRouter();

  // Получение изображения продукта
  // eslint-disable-next-line no-nested-ternary
  const imageLink = product?.mainImageURL
    ? uploadProductsImage(product.mainImageURL)
    : product?.merchants?.[0]?.mainImageURL
      ? uploadProductsImage(product.merchants[0].mainImageURL)
      : '';

  // Цена продукта
  const price = product?.prices?.find(
    (p: { type: PriceTypeEnum }) => p.type === PriceTypeEnum.PRICE,
  )?.value;
  const random = Math.floor(Math.random() * (10 - 3 + 1)) + 3;

  // background: linear-gradient(92.59deg, #0177EC 0.08%, #0138C0 99.92%);

  return (
    <>
      {block?.settings?.enableDouble ? (
        <Stack
          direction="row"
          width="100%"
          alignItems="center"
          sx={{
            position: 'relative',
            borderRadius: '5px', // следим за соответствием углов
          }}
          onClick={() => router.push(`/product${product?.id}`)}
        >
          <Stack
            sx={{
              background: '#0177EC',
              p: 1,
              borderRadius: '0 5px 5px 0', // Скругляем только левую сторону
            }}
          >
            <Typography
              sx={{ fontSize: '12px', textAlign: 'center', color: 'white' }}
            >
              {random}%
            </Typography>
            <Typography sx={{ color: 'white', fontSize: '10px' }}>
              הנחה
            </Typography>
          </Stack>
          <Image
            src={uploadProductsImage(imageLink)}
            sx={{
              width: '100px', // Фиксированная ширина
              height: '49px', // Фиксированная высота
              objectFit: 'cover', // Заполняет область, обрезая излишки
              borderRadius: ' 5px 0 0 5px', // Скругленные углы (если нужно)
            }}
          />
        </Stack>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%', // уменьшенная ширина карточки
            p: 0.5, // уменьшенные внутренние отступы
          }}
          onClick={() => router.push(`/product/${product?.id}`)}
        >
          <Stack width="100%" alignItems="center" sx={{ position: 'relative' }}>
            <Box
              sx={{
                backgroundSize: 'cover',
                backgroundImage: `url(${imageLink})`,
                backgroundRepeat: 'no-repeat',
                height: !block?.settings?.enablePriceOnTop ? '55px' : '75px',
                width: '100%',
                borderRadius: '4px',
                transform: block.settings.enableRotation
                  ? 'rotate(-20deg)'
                  : 'none',
                mt: block.settings.enableRotation ? '15px' : 0,
              }}
            />
            {block?.settings?.enablePriceOnTop && (
              <Box
                sx={{
                  direction: 'rtl',
                  position: 'absolute',
                  top: '-10px',
                  left: 5,
                  background: '#0155D4',
                  borderRadius: '10px',
                  px: 1,
                  py: 0.5,
                }}
              >
                <Typography
                  sx={{ color: 'white', fontSize: '12px', fontWeight: 1000 }}
                >
                  שובר בשווי
                </Typography>
                <Typography
                  sx={{ color: 'white', fontSize: '12px', fontWeight: 1000 }}
                >
                  ₪{price}
                </Typography>
              </Box>
            )}
            <Stack alignItems="center" sx={{ mt: 0.25, textAlign: 'center' }}>
              {!block.settings.disableName && (
                <Typography sx={{ width: '100%', fontSize: '0.625rem' }}>
                  {product?.title}
                </Typography>
              )}
              {block.settings.enablePrice && (
                <Typography sx={{ width: '100%', fontSize: '0.625rem' }}>
                  שובר ₪{price}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Box>
      )}
    </>
  );
};

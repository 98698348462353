'use client';

import { useRef } from 'react';
import Slider from 'react-slick';

import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import { SelectChangeEvent } from '@mui/material/Select';

import { useTranslate } from '../../locales';
import { Ib2bProduct } from '../../types/b2b';
import Iconify from '../../components/iconify';
import { useRouter } from '../../routes/hooks';
import { BlockType } from '../../types/generator';
import { AlternativeProduct } from './alternativeProduct';
import { useAppContext } from '../../contexts/AppContext';
import { useResponsive } from '../../hooks/use-responsive';
import Image from '../../components/image';
import { uploadProductsImage } from '../../helpers/uploadProductsImage';
import { PriceTypeEnum } from '../../types/product';
import ProductFilters from './product-filters';


type Props = {
  block: BlockType | undefined;
  dataFiltered: Ib2bProduct[];
  open: boolean;
  toggleDrawer: Function;
  filterView: any;
  dataFilteredCustom: Ib2bProduct[];
  actionButtons: any;
  handleFilterCategory: Function;
}

const ScrollbarContainer = styled('div')({
  display: 'flex',
  overflowX: 'auto',
  whiteSpace: 'nowrap',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
});


export const AlternativeProducts = ({
                                      block,
                                      dataFiltered,
                                      open,
                                      toggleDrawer,
                                      filterView,
                                      actionButtons,
                                      handleFilterCategory,
                                    }: Props) => {
  const router = useRouter();
  const sliderRef = useRef<any>(null);
  const { t } = useTranslate();
  const isMobile = useResponsive('down', 'sm');
  const { dispatch, state } = useAppContext();

  const settings = {
    // dots: !(block?.settings?.isAlternativeDesign && !isMobile),
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: !isMobile ? 6 : 3,
    slidesToScroll: 1,
  };


  const handleClick = (categoryTitle: string) => {
    const event = {
      target: { value: [categoryTitle] },
    } as SelectChangeEvent<string[]>;

    handleFilterCategory(event);
  };

  return (
    <>
      {!block?.settings?.priceOnTopVariant ? (
        <>
          {block?.settings?.partialSize ? (
            <Box sx={{ '& .MuiGrid-root': { width: '100%', margin: 0 } }}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" my={2}>
                <Typography sx={{ fontSize: isMobile ? '20px' : '24px', fontWeight: 600 }}>
                  שוברים
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  onClick={() => router.push(`/${actionButtons[0]?.link}`)}
                  sx={{ cursor: 'pointer' }}
                >
                  <Typography
                    sx={{
                      fontSize: isMobile ? '12px' : '16px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: 'normal',
                    }}
                  >
                    {actionButtons[0]?.label}
                  </Typography>
                  <Iconify
                    icon={
                      // params.lng !== 'he' ? 'iconamoon:arrow-right-2' : 'iconamoon:arrow-left-2'
                      'iconamoon:arrow-left-2'
                    }
                  />
                </Stack>
              </Stack>
              {/* <Grid */}
              {/*  container */}
              {/*  spacing={3} */}
              {/*  rowGap={3} */}
              {/*  justifyContent="center" */}
              {/*  sx={{ '& .MuiGrid-item': { p: 0, px: 1 }, justifyContent: 'center' }} */}
              {/* > */}
              <Box
                sx={{
                  '& .slick-slide': {
                    direction: 'rtl',
                    px: { md: 1, xs: 1 },
                  },
                  '& .slick-track': {
                    marginTop: '15px',
                  },
                }}
              >
                <Slider ref={sliderRef} {...settings}>
                  {dataFiltered
                    ?.map((product: Ib2bProduct, idx: number) => (
                      <AlternativeProduct block={block} product={product} key={idx} />
                    ))}
                </Slider>
              </Box>
              {/* </Grid> */}
            </Box>
          ) : (
            <Box sx={{ '& .MuiGrid-root': { width: '100%', margin: 0 } }}>
              {isMobile ? (
                <>
                  <Stack width={1} my={3}>
                    <Iconify icon="mdi:filter" onClick={toggleDrawer(true)} />
                  </Stack>
                  <Drawer open={open} onClose={toggleDrawer(false)}>
                    {filterView}
                  </Drawer>
                </>
              ) : (
                <>{filterView}</>
              )}
              <>
                {block?.settings?.enableCategoriesFilter && (
                  <Stack direction="row" mb={2} mt={1}>
                    <ScrollbarContainer>
                      {state.categories.map((category: any) => (
                        <Chip
                          label={category.title}
                          onClick={() => handleClick(category.title)}
                          sx={{
                            mx: 1,
                            background: 'linear-gradient(90deg, #0881EB 80%, #003DE2 100%)',
                            color: 'white',
                            borderRadius: '10px',
                          }}
                        />
                      ))}
                    </ScrollbarContainer>
                  </Stack>
                )}

                <Grid
                  container
                  spacing={3}
                  rowGap={3}
                  justifyContent="center"
                  sx={{ '& .MuiGrid-item': { p: 0, px: 1 }, justifyContent: 'center' }}
                >
                  {dataFiltered?.map((product: Ib2bProduct, idx: number) => (
                    <AlternativeProduct block={block} product={product} key={idx} />
                  ))}
                </Grid>
              </>
            </Box>
          )}
        </>
      ) : (
        <Box>
          <Stack gap={1}>
            {filterView}
          </Stack>
          <Grid container spacing={3} rowGap={3} justifyContent="center">
            {dataFiltered?.map((product: Ib2bProduct, id: number) => {
              // eslint-disable-next-line no-nested-ternary
              const imageLink = product?.mainImageURL
                ? uploadProductsImage(product.mainImageURL)
                : product?.merchants?.[0]?.mainImageURL
                  ? uploadProductsImage(product.merchants[0].mainImageURL)
                  : '';
              const price = product?.prices?.find(
                (p: { type: any }) => p.type === PriceTypeEnum.PRICE,
              )?.value;
              const businessPrice = product?.prices?.find(
                (p: { type: any }) =>
                  p.type === PriceTypeEnum.BUSINESS_PRICE,
              )?.value;
              return (
                <Grid item xs={isMobile ? 6 : 2.4}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: '100%', // уменьшенная ширина карточки
                      p: 0.5, // уменьшенные внутренние отступы
                    }}
                  >
                    <Stack
                      width="100%"
                      alignItems="center"
                      sx={{ position: 'relative' }}
                    >
                      <Image
                        src={imageLink}
                        sx={{
                          width: '100%',
                          height: '75px',
                        }}
                      />
                      <Box
                        sx={{
                          direction: 'rtl',
                          position: 'absolute',
                          top: '-10px',
                          left: 5,
                          background: '#0155D4',
                          borderRadius: '10px',
                          px: 1,
                          py: 0.5,
                        }}
                      >
                        <Typography
                          sx={{
                            color: 'white',
                            fontSize: '12px',
                            fontWeight: 1000,
                          }}
                        >
                          שובר בשווי
                        </Typography>
                        <Typography
                          sx={{
                            color: 'white',
                            fontSize: '12px',
                            fontWeight: 1000,
                          }}
                        >
                          ₪{businessPrice}
                        </Typography>
                      </Box>
                      <Stack
                        alignItems="center"
                        sx={{ mt: 0.25, textAlign: 'center' }}
                      >
                        {!block.settings.disableName && (
                          <Typography
                            sx={{ width: '100%', fontSize: '0.625rem' }}
                          >
                            {product?.title}
                          </Typography>
                        )}
                        {block.settings.enablePrice && (
                          <Typography
                            sx={{ width: '100%', fontSize: '0.625rem' }}
                          >
                            שובר במחיר ₪{price}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      )}
    </>
  );
};

'use client';

import { FC } from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import Image from '../../../../components/image';
import Iconify from '../../../../components/iconify';
import { ComponentTypeProps } from '../../../../types/page-generator';

export const Sales: FC<ComponentTypeProps> = ({ block, params }) => (
    <Stack width={1}>
      <Stack direction="row" width={1}>
        <Button sx={{ color: 'background: rgba(255, 255, 255, 1)' }}>חיפוש</Button>
        <TextField select label="אזור" />
        <TextField select label="קטגוריה" />
        <TextField select label="סוג" />
        <TextField
          {...params}
          placeholder="חיפוש"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{ ml: 1, color: 'text.disabled', cursor: 'pointer' }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <Grid container spacing={1}>
        <Grid item md={4} xs={12}><Image src="/assets/images/b-smart/sales/Sale 1.png" /></Grid>
        <Grid item md={4} xs={12}><Image src="/assets/images/b-smart/sales/Sale 2.png" /></Grid>
        <Grid item md={4} xs={12}><Image src="/assets/images/b-smart/sales/Sale 3.png" /></Grid>
      </Grid>
    </Stack>
  );
